import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const InfoCards = () => {
  return (
    <section className="stack-parent info-cards mt-lg-2">
      <StaticImage
        as="div"
        loading="eager"
        className="stack-child"
        src="../../images/learn/meati-steak-cutting-board.jpg"
        quality={95}
        formats={['AUTO', 'WEBP']}
        alt="Prepared Meati Crispy Cutlets at family dinner"
        placeholder="blurred"
        layout="fullWidth"
        breakpoints={[575, 767, 991, 1199, 1399, 1920, 2999]}
      />
      <Container className="stack-child py-2 py-lg-4 py-xl-6" fluid="lg">
        <Row>
          <Col xs="10" sm="6" md="5" xl="4" className="d-flex align-items-center">
            <div className="bg-danger p-2 p-xl-3 px-xxl-4 text-warning rounded-4">
              <h2>Good for you AND delicious?! C'mon.</h2>
              <p className="mb-0">“It's a texture thing...” No, really. Lucky for us all, mushroom root is very similar to muscle tissue, making it easy for our culinary team to gently form our main ingredient into any shape, texture and flavor. Like a perfectly blank canvas, we marinate our mushroom root with a handful of natural ingredients (spices, herbs, all that) to create the delicious protein and fiber-rich cut you know and love.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default InfoCards
