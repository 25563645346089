import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container } from 'react-bootstrap'
import Layout from '../components/layout'
import Seo from '../components/seo'
import VideoPlayer from '../components/common/video-player'
import MyceliumScroller from '../components/sections/mycelium-scroller'
import InfoCards from '../components/sections/info-cards'
import IconList from '../components/sections/icon-list'
import BlogTiles from '../components/sections/blog-tiles'
import MainCTA from '../components/sections/main-cta'

const WhatIsPage = () => {
  const url = 'https://player.vimeo.com/video/815104796?h=d80e2b384a'

  return (
    <Layout>
      <Seo title="What is Meati™?" />
      <header className="bg-info text-light text-center pb-1 pt-2 pt-md-3 pt-lg-4 pt-xl-5 pt-xxl-6" id="learnHero">
        <div className="container-narrower">
          <h1 className="text-center fw-bolder stack-child d-flex align-items-center justify-content-center">The Deets</h1>
          <p className="mb-0 fs-5 lh-sm mb-2 mb-xl-3 mb-xxl-4">
            <b>At Meati Foods, we believe food should be delicious, simple, and sustainable. </b>
            That's why we do everything ourselves, from growing our mushroom root main ingredient to crafting our nutrient-rich whole cuts.
          </p>
        </div>
        <Container>
          <VideoPlayer url={url} containerClass="rounded-4 overflow-hidden" ratio="16x9" buttonColor="light" progressColor="warning" />
        </Container>
      </header>
      <MyceliumScroller />
      <InfoCards />
      <IconList />
      <BlogTiles title="The Underground" subtitle="A deeper look into all things Meati" className="py-4 bg-dark outlined-blog-tiles" id="featuredBlogs" type="featured" />
      <section>
        <StaticImage
          as="div"
          className="image-fullwidth"
          src="../images/learn/family-eating-meati.jpg"
          quality={95}
          formats={['AUTO', 'WEBP']}
          alt="Family eating Meati™"
          placeholder="blurred"
          layout="fullWidth"
        />
      </section>
      <MainCTA />
    </Layout>
  )
}

export default WhatIsPage
